import React from "react";

const Team = () => {
  return (
    <>
      <div className="container-fluid py-2 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-2 ">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">
              Team Members
            </h5>
            <h1 className="mb-0">
              Professional Staff Ready to Help Your Business
            </h1>
          </div>
          <div className="row g-5">
            {/* Gauri */}
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src="team/gauri.jpg"
                    alt=""
                    style={{ width: "50px", height: "360px" }}
                  />
                  {/* <div className="team-social">
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
            </div> */}
                </div>
                <div className="text-center py-4 ">
                  <h4 className="text-primary">Gauri Birari</h4>
                  <p className="text-uppercase m-0">Front End Developer</p>
                </div>
              </div>
            </div>
            {/* sakshi */}
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src="team/sakshi.jpeg"
                    alt=""
                    style={{ width: "60px", height: "360px" }}
                  />
                  {/* <div className="team-social">
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
            </div> */}
                </div>
                <div className="text-center py-4">
                  <h4 className="text-primary">Sakshi Kadave</h4>
                  <p className="text-uppercase m-0">Backend Developer</p>
                </div>
              </div>
            </div>
            {/* Gaurav */}
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src="team/GauravB.jpeg"
                    alt=""
                    style={{ width: "60px", height: "360px" }}
                  />
                  {/* <div className="team-social">
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
            </div> */}
                </div>
                <div className="text-center py-4">
                  <h4 className="text-primary">Gaurav Bhambere</h4>
                  <p className="text-uppercase m-0">App Developer</p>
                </div>
              </div>
            </div>
            {/* Ram Gutte */}
            {/* <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
        <div className="team-item bg-light rounded overflow-hidden">
          <div className="team-img position-relative overflow-hidden">
            <img className="img-fluid w-100" src="team/RamG.jpg" alt="" style={{ width: '60px', height: '360px' }} />
            {/* <div className="team-social">
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
            </div> */}
            {/* </div>
          <div className="text-center py-4">
            <h4 className="text-primary">Ram Gutte</h4>
            <p className="text-uppercase m-0">Data Analyst</p>
          </div>
        </div>
      </div> */}

            {/* vikas  */}
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src="team/vikas.jpg"
                    alt=""
                    style={{ width: "60px", height: "360px" }}
                  />
                  {/* <div className="team-social">
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
            </div> */}
                </div>
                <div className="text-center py-4">
                  <h4 className="text-primary">Vikas Warwatte</h4>
                  <p className="text-uppercase m-0">Full Stack Web Developer</p>
                </div>
              </div>
            </div>
            {/* Ram Telange */}
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
              <div className="team-item bg-light rounded overflow-hidden">
                <div className="team-img position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src="team/profile.jpg"
                    alt=""
                    style={{ width: "60px", height: "360px" }}
                  />
                  {/* <div className="team-social">
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
              <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
            </div> */}
                </div>
                <div className="text-center py-4">
                  <h4 className="text-primary">Telange Rameshwar </h4>
                  <p className="text-uppercase m-0">Front End Developer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
