// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";

// export default function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }

import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ targetId }) => {
  const { pathname } = useLocation();
  const targetRef = useRef(null);

  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo(0, 0); // Scroll to the top if targetRef is not available
    }
  }, [pathname, targetId]); // Depend on targetId if you want to re-trigger scroll on id change

  return (
    <div ref={targetRef} id={targetId} />
  );
};

export default ScrollToTop;
