import React, { useEffect } from "react";
import { FaArrowRight } from "react-icons/fa"; // Import FaArrowRight

import AOS from "aos";
import "aos/dist/aos.css";

import BackToTopButton from "../COMPONENTS/BackToTopButton";

const Services = () => {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 2000,
    });
  }, []);

  const servicesData = [
    {
      icon: "fa fa-shield-alt",
      title: "Cyber Security",
      description:
        "Protect your business from cyber threats with our advanced security solutions. Our comprehensive approach includes threat detection, vulnerability assessment, and proactive security measures to safeguard your sensitive data and infrastructure.",
      link: "#",
    },
    {
      icon: "fa fa-chart-pie",
      title: "Data Analytics",
      description:
        "Gain valuable insights from your data with our powerful analytics tools. We utilize cutting-edge technologies and data visualization techniques to analyze your data, identify trends, and make informed decisions that drive business growth and innovation.",
      link: "#",
    },
    {
      icon: "fa fa-code",
      title: "Web Development",
      description:
        "Create stunning websites and web applications tailored to your business needs. Our experienced team of developers specializes in front-end and back-end technologies to deliver user-friendly, responsive, and scalable web solutions that enhance your online presence and engage your audience.",
      link: "#",
    },
    {
      icon: "fab fa-android",
      title: "Application Development",
      description:
        "Build innovative mobile apps for iOS and Android platforms. From concept to deployment, we offer end-to-end mobile app development services, including UI/UX design, native and cross-platform development, testing, and maintenance, to bring your app idea to life and reach your target audience effectively.",
      link: "#",
    },
    {
      icon: "fa fa-search",
      title: "SEO Optimization",
      description:
        "Improve your online visibility and attract more customers with our SEO services. We employ industry best practices and white-hat SEO techniques to optimize your websites performance, increase organic traffic, and rank higher in search engine results pages (SERPs), ensuring maximum exposure and ROI for your business.",
      link: "#",
    },
    {
      icon: "fa fa-bullhorn",
      title: "Digital Marketing",
      description:
        "Boost your online presence and reach your target audience with our comprehensive digital marketing services. Our expert team creates tailored strategies encompassing SEO, SEM, social media marketing, content marketing, and email marketing to drive brand awareness, customer engagement, and conversion rates.",
      link: "#",
    },
    {
      icon: "fa fa-handshake",
      title: "Business Consultancy",
      description:
        "Maximize your business potential with our specialized consultancy services. Benefit from our extensive expertise and industry insights to streamline operations, achieve strategic objectives, and foster sustainable growth. Our consultancy covers a spectrum of areas including business strategy, process enhancement, market analysis, and organizational development. By partnering with us, you empower your organization to make data-driven decisions, adapt to market dynamics, and thrive in today's competitive landscape.",
      link: "#",
    },
    {
      icon: "fa fa-wifi",
      title: "Internet of Things (IoT)",
      description:
        "Leverage the transformative potential of IoT to seamlessly connect and automate devices, gather actionable insights, and drive operational excellence. Our IoT solutions empower businesses to achieve real-time monitoring, predictive maintenance, and remote management, enhancing operational efficiency and customer experiences. By harnessing IoT technology, businesses can unlock new opportunities for innovation, optimize resource utilization, and gain a competitive edge in today's interconnected world.",
      link: "#",
    },
    {
      icon: "fa fa-chalkboard-teacher",
      title: "Training & Workshops",
      description:
        "Empower students with the knowledge and skills needed to excel in today's digital landscape. Our training programs and workshops cover a wide array of essential topics, including web development, cybersecurity, software testing, and data analysis. Whether students are looking to enhance their technical expertise, strengthen their cybersecurity practices, ensure software quality, or derive valuable insights from data, our training sessions are tailored to meet their specific requirements and objectives.",
      link: "https://docs.google.com/forms/d/e/1FAIpQLScb7vXXgYFhJ7-Cnd5V06GKZ8HqR_aJhQbQZc8QVKu74W4Ftg/viewform?usp=sharing",
    },
  ];

  return (
    <>
      <div className="container-fluid py-2 wow fadeInUp " data-wow-delay="0.5s">
        <div className="container py-2">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">
              Our Services
            </h5>
            <h1 className="mb-0">
              Custom IT Solutions for Your Successful Business
            </h1>
            <p className="lead">
              We offer a range of services to help your business thrive in the
              digital age.
            </p>
          </div>
          <div className="row g-5 justify-content-center">
            {servicesData.map((service, index) => (
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay={`${0.3 + index * 0.3}s`}
                key={index}
              >
                <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div className="service-icon">
                    <i className={service.icon}></i>
                  </div>
                  <h4 className="mb-3">{service.title}</h4>
                  <p className="m-0 text-justify">{service.description}</p>
                  <a
                    className="btn btn-lg btn-primary rounded"
                    href={service.link}
                  >
                    <i>
                      <FaArrowRight></FaArrowRight>
                    </i>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <BackToTopButton></BackToTopButton>
    </>
  );
};

export default Services;
