import React, { useEffect, useState } from "react";
import BackToTopButton from '../COMPONENTS/BackToTopButton'

const images = [
  { id: "1", imageName: "g1 (2).jpg", tag: "social" },
  { id: "2", imageName: "gd.jpg", tag: "cultural" },
  { id: "3", imageName: "g1 (6).jpg", tag: "celebration" },
  // { id: "4", imageName: "img4.jpg", tag: "all" },
  { id: "5", imageName: "g1 (3).jpg", tag: "social" },
  { id: "6", imageName: "gd5.jpg", tag: "cultural" },
  // { id: "7", imageName: "g1 (7).jpg", tag: "celebration" },
  // { id: "8", imageName: "img8.jpg", tag: "all" },
  { id: "9", imageName: "g1 (4).jpg", tag: "social" },
  { id: "10", imageName: "g.jpg", tag: "cultural" },
  // { id: "11", imageName: "g1 (7).jpg", tag: "celebration" },
  // { id: "12", imageName: "gd3.jpg", tag: "all" },
  { id: "13", imageName: "g1 (5).jpg", tag: "social" },
  // { id: "14", imageName: "gd4.jpg", tag: "cultural" },
  // { id: "16", imageName: "g1 (19).jpg", tag: "celebration" },
  { id: "17", imageName: "g1 (22).jpg", tag: "social" },
  // { id: "18", imageName: "n (3).jpg", tag: "celebration" },
  { id: "19", imageName: "n (4).jpg", tag: "celebration" },
];

const Gallary = () => {
  const [tag, setTag] = useState("all");
  const [filteredImages, setFilteredImages] = useState([]);

  useEffect(() => {
    tag === "all"
      ? setFilteredImages(images)
      : setFilteredImages(images.filter((image) => image.tag === tag));
  }, [tag]);

  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const getImg = (imageName) => {
    setTempImgSrc(imageName);
    setModel(true);
  };
  

  return (
    <>

      <div className="container-fluid py-2 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container ">
                <div className="section-title text-center position-relative pb-2  mx-auto" style={{maxWidth: '600px'}}>
                    <h2 className="fw-bold text-primary text-uppercase">Company Celebrations</h2>
                </div></div></div>

      {/* LIGHT BOX GALLERY */}

      <div className={model ? "model open" : "model"}>
        <img src={tempimgSrc} alt="" />
        <i className="fa fa-close close" onClick={() => setModel(false)}></i>
        {/* <i class="fa-solid fa-chevron-right right"></i> */}
      </div>

      <div className="container justify-content-center" handleSetTag={setTag}>
        <div className="gallery-btn">
          <TagButton name="social" handleSetTag={setTag} />
          <TagButton name="cultural" handleSetTag={setTag} />
          <TagButton name="celebration" handleSetTag={setTag} />
          <TagButton name="all" handleSetTag={setTag} />
        </div>

        <div className="gallery-imgs">
          {filteredImages.map((image) => (
            <div key={image.id} className="img-card">
              <img
                className="gallery"
                src={`../images/${image.imageName}`}
                alt=""
                onClick={() => getImg(`/images/${image.imageName}`)}
              />
            </div>
          ))}
        </div>
      </div>

   
      <BackToTopButton></BackToTopButton>

    </>
  );
};

 
const TagButton = ({ name, handleSetTag }) => {
  return (
    <>
      <button
        className="btn btn-info rounded-0 gallery-tag"
        onClick={() => handleSetTag(name)}
      >
        {name.toUpperCase()}
      </button>
    </>
  );
};
  <BackToTopButton></BackToTopButton>

export default Gallary;
