import React, { useEffect, useState } from 'react';
import { FaArrowUp } from 'react-icons/fa';

const BackToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const checkScrollTop = () => {
        if (window.scrollY > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => window.removeEventListener('scroll', checkScrollTop);
    }, []);

    return (
        <a
            href="#"
            className={`btn btn-lg btn-primary btn-lg-square rounded back-to-top ${isVisible ? 'show' : ''}`}
            onClick={(e) => {
                e.preventDefault(); // Prevent default anchor behavior
                scrollToTop();
            }}
        >
            <i><FaArrowUp /></i>
        </a>
    );
};

export default BackToTopButton;
