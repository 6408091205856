import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import About from "./About";
import BackToTopButton from "../COMPONENTS/BackToTopButton";
import AOS from "aos";
import "aos/dist/aos.css";
import Services from "./Services";
import CountUp from "react-countup";
import Marquee from "react-fast-marquee";
import ScrollTrigger from "react-scroll-trigger";
import Team from "./Team";
import Interns from "./Interns";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Feedback from "./Feedback";
const Home = () => {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      easing: "ease-out-cubic",
    });
  }, []);
  const [counter, setCounter] = useState(false);

  const [carouselOptions, setCarouselOptions] = useState({
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
    margin: 20,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
  });

  return (
    <>
      <div className="container-fluid facts pt-lg-0 mt-n5">
        <div className="container py-1 pt-lg-0">
          <div className="row gx-0">
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
              <div
                className="bg-primary shadow d-flex align-items-center justify-content-center p-2"
                style={{ height: "130px" }}
              >
                <div
                  className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-users text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">Happy Clients</h5>
                  <h1 className="mb-0" data-toggle="counter-up">
                    <ScrollTrigger
                      onEnter={() => setCounter(true)}
                      onExit={() => setCounter(false)}
                    >
                      {counter && (
                        <h1 className="text-white mb-0 fw-bold m">
                          <CountUp start={0} end={53} duration={1} />+
                        </h1>
                      )}
                    </ScrollTrigger>
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
              <div
                className="bg-light shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "130px" }}
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-check text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-primary mb-0">Projects Done</h5>
                  <h1 className="mb-0" data-toggle="counter-up">
                    <ScrollTrigger
                      onEnter={() => setCounter(true)}
                      onExit={() => setCounter(false)}
                    >
                      {counter && (
                        <h1 className="text-dark mb-0 fw-bold m">
                          <CountUp start={0} end={35} duration={1} />+
                        </h1>
                      )}
                    </ScrollTrigger>
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
              <div
                className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "130px" }}
              >
                <div
                  className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-award text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">Win Awards</h5>
                  <h1 className="mb-0" data-toggle="counter-up">
                    <ScrollTrigger
                      onEnter={() => setCounter(true)}
                      onExit={() => setCounter(false)}
                    >
                      {counter && (
                        <h1 className="text-white mb-0 fw-bold m">
                          <CountUp start={0} end={12} duration={1} />+
                        </h1>
                      )}
                    </ScrollTrigger>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-2">
          <div className=" row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-1">
                <h5 className="fw-bold text-primary text-uppercase">
                  About Us
                </h5>
                <h1 className="mb-0">The Best IT Solution for Your Business</h1>
              </div>
              <p
                className="mb-4 mt-0"
                style={{ lineHeight: "1.6", fontSize: "17px" }}
              >
                The Innovative Solutions IT Services, founded in 2020, aims to
                enhance organizations by delivering innovative ideas.
                Specializing in custom application development, our ethical and
                strategic approach ensures timely deliverables and business
                growth. With domain and technical expertise, we provide tailored
                solutions within budget, fostering client satisfaction. As a
                leading IT services provider, we offer consulting and business
                process services, focusing on delivering value and
                customer-centric solutions.
              </p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>Innovative
                    Excellence
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>Expertise
                    That Shines
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>Always-On
                    Assistance
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>Fair Prices
                  </h5>
                </div>
              </div>
              <div
                className="d-flex align-items-center mb-4 wow fadeIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-phone-alt text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Call to ask any question</h5>
                  <h4 className="text-primary mb-0">+91 9371001138</h4>
                </div>
              </div>
              <NavLink to="/contact">
                <Button className="btn btn-primary py-3 px-5 mt-3 wow zoomIn">
                  Request A Quote
                </Button>
              </NavLink>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src="../about/about.jpg"
                  style={{ minHeight: "500px", objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Back to Top --> */}
      {/* <a href="#" className="btn btn-lg btn-primary btn-lg-square rounded back-to-top "><i><FaArrowUp></FaArrowUp></i></a> */}
      <BackToTopButton></BackToTopButton>
      <div className="container-fluid py-2 wow fadeInUp " data-wow-delay="0.1s">
        <div className="container py-2">
          <div
            className="section-title text-center position-relative pb-3 mb-2 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">
              Why Choose Us
            </h5>
            <h1 className="mb-0">
              Boost Your Business with Our Professional Services
            </h1>
            <p className="lead">
              Discover why we're the right choice for your business growth.
            </p>
          </div>
          <div className="row g-5">
            <div className="col-lg-4">
              <div className="row g-5">
                <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                  <div
                    className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-cubes text-white"></i>
                  </div>
                  <h4>Industry Leading Solutions</h4>
                  <p className="mb-0">
                    Our solutions are designed to meet the highest industry
                    standards, ensuring your business stays ahead of the curve.
                  </p>
                </div>
                <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                  <div
                    className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-award text-white"></i>
                  </div>
                  <h4>Award-Winning Services</h4>
                  <p className="mb-0">
                    We pride ourselves on delivering award-winning services that
                    drive real results for our clients.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4  wow zoomIn"
              data-wow-delay="0.9s"
              style={{ minHeight: "350px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.1s"
                  src="img/feature.jpg"
                  style={{ objectFit: "cover" }}
                  alt="Feature"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row g-5">
                <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                  <div
                    className="bg-primary rounded d-flex align-items-center justify-content-center mb-2"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-users-cog text-white"></i>
                  </div>
                  <h4>Expert Team</h4>
                  <p className="mb-0">
                    Our team of professionals brings a wealth of experience and
                    expertise to every project, ensuring its success.
                  </p>
                </div>
                <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                  <div
                    className="bg-primary rounded d-flex align-items-center justify-content-center mb-2"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-phone-alt text-white"></i>
                  </div>
                  <h4>24/7 Support</h4>
                  <p className="mb-0">
                    We provide round-the-clock support to address any issues or
                    concerns you may have, ensuring peace of mind.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Services></Services>

      <div className="container ">
        <div>
          <div
            className="container-fluid py-2 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="container py-4">
              <div
                className="section-title text-center position-relative pb-3  mx-auto "
                style={{ maxWidth: "600px" }}
              >
                <h2 className="mb-0">With Whom We Work</h2>
              </div>{" "}
            </div>{" "}
          </div>
          <hr />
        </div>
        <Marquee pauseOnHover speed={50}>
          <div>
            <img
              src="/logos/venkat.png"
              className="m-5 rounded-circle"
              alt=""
              width="130"
              hieght="130"
            />
          </div>

          <div>
            <img
              src="/logos/kbt1.jpg"
              className="m-5 rounded-circle"
              alt=""
              width="130"
              hieght="130"
            />
          </div>
          <div>
            <img
              src="logos/logo.png"
              className="m-5"
              alt=""
              width="130"
              hieght="130"
            />
          </div>

          <div>
            <img
              src="/img/client/welfare.png"
              className="m-5 rounded-circle"
              alt=""
              width="130"
              hieght="130"
            />
          </div>
          {/* <div>
                        <img src="/logos/logo8.jpg" className='m-5' alt="" width="130" hieght="130" />
                    </div> */}
          {/* <div>
                        <img src="/logos/logo9.jpeg" className='m-5' alt="" width="130" hieght="130" />
                    </div> */}
          <div>
            <img
              src="/logos/logo1.png"
              className="m-5"
              alt=""
              width="130"
              hieght="130"
            />
          </div>
          <div>
            <img
              src="/logos/sapkal.jpg"
              className="m-5"
              alt=""
              width="130"
              hieght="130"
            />
          </div>
          <div>
            <img
              src="/logos/nmc.png"
              className="m-5"
              alt=""
              width="130"
              hieght="130"
            />
          </div>
          <div>
            <img
              src="/logos/cki.jpeg"
              className="m-5 rounded-circle "
              alt="cki"
              width="130"
              hieght="130"
            />
          </div>
        </Marquee>
      </div>

      <Team></Team>

      {/* <Testimonial></Testimonial> */}

      <Feedback></Feedback>
    </>
  );
};

export default Home;
