import React, { useEffect,useState } from 'react';
import { FaArrowUp } from "react-icons/fa";
import BackToTopButton from '../COMPONENTS/BackToTopButton'

const Contact = () => {
const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

    return (
        <>
                <a href="/#/contact" className="btn btn-lg btn-primary btn-lg-square rounded back-to-top "><i><FaArrowUp></FaArrowUp></i></a>

        <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: '600px'}}>
                    <h5 className="fw-bold text-primary text-uppercase">Contact Us</h5>
                    <h1 className="mb-0">Have a Question? Get in Touch!</h1>
                </div>
                <div className="row g-5 mb-2">
            <div className="col-md-6">
                <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
                    <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: '60px', height: '60px'}}>
                        <i className="fa fa-phone-alt text-white"></i>
                    </div>
                    <div className="ps-4">
                        <h5 className="mb-2">Call Us</h5>
                        <h4 className="text-primary mb-0">+91 9371001138</h4>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.4s">
                    <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: '60px', height: '60px'}}>
                        <i className="fa fa-envelope-open text-white"></i>
                    </div>
                    <div className="ps-4">
                        <h5 className="mb-2">Email Us</h5>
                        <h4 className="text-primary mb-0">enquiry@innovativesolutions.in</h4>
                    </div>
                </div>
            </div>
        </div>
                <div className="row g-5">
                    <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
                        <form>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <input type="text" className="form-control border-0 bg-light px-4" placeholder="Your Name" style={{height: '55px'}} />
                                </div>
                                <div className="col-md-6">
                                    <input type="email" className="form-control border-0 bg-light px-4" placeholder="Your Email" style={{height: '55px'}} />
                                </div>
                                <div className="col-12">
                                    <input type="number" className="form-control border-0 bg-light px-4" placeholder="Mobile No." style={{height: '55px'}} />
                                </div>
                                <div className="col-12">
                                    <textarea className="form-control border-0 bg-light px-4 py-3" rows="4" placeholder="Message"></textarea>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
                        <iframe className="position-relative rounded w-100 h-100"
             src="https://maps.google.com/maps?width=435&amp;height=414&amp;hl=en&amp;q=the innovative solutions nashik&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              frameBorder="0" style={{minHeight: '350px', border: '0'}} allowFullScreen="" aria-hidden="false"
                            tabIndex="0"></iframe>
                    </div>

                    <div className="row g-5 mb-5">
            <div className="col-md-6">
                <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
                    <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: '60px', height: '60px'}}>
                    <i class="fa fa-map-marker-alt text-white"></i>
                        </div>
                        <div class="ps-4">
                            <h5 class="mb-2 text-primary">Visit Our Office at Nashik</h5>
                            <h6 class=" mb-0">flat no. 1, Urvashi Apartment, Kusumagraj Smarak Path, Bhavik Nagar, Shreerang Nagar, Nashik</h6>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="d-flex align-items-center wow fadeIn" >
                    <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: '60px', height: '60px'}}>
                    <i class="fa fa-map-marker-alt text-white"></i>
                        </div>
                        <div class="ps-3">
                            <h5 class="mb-2 text-primary">Visit our Office at  Pune</h5>
                            <h6 class="mb-0">Office no.23, 3rd Floor, Aston Plaza, Narhe Ambegaon Rd, above Star Bazaar, Ambegaon Budruk, Pune</h6>
                    </div>
                </div>
            </div>
        </div>
                </div>
            </div>
        </div>
        <BackToTopButton></BackToTopButton>

        </>
    );
}

export default Contact;
