import React from 'react';
import { FaArrowUp } from "react-icons/fa";
import BackToTopButton from '../COMPONENTS/BackToTopButton'
import ScrollToTop from '../COMPONENTS/ScrollToTop';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
const About = () => {
  return (
    <>
    <br />
    <ScrollToTop targetId="target-section" />
      {/* Other components and routes */}
      <section id="target-section">
        {/* Content you want to scroll to */}
      </section>
    <div className="container-fluid py-2 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title position-relative pb-3 mb-2 mx-auto">
                        <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
                        <h1 className="mb-0">The Best IT Solution for Your Business</h1>
                    </div>
                    <p className="mb-4 mt-0" style={{ lineHeight: '1.6', fontSize: '17px' }}>The Innovative Solutions IT Services, founded in 2020, aims to enhance organizations by delivering 
                    innovative ideas. Specializing in custom application development, our 
                    ethical and strategic approach ensures timely deliverables and business growth. With 
                    domain and technical expertise, we provide tailored solutions within budget, fostering 
                    client satisfaction. As a leading IT services provider, we offer consulting and business 
                    process services, focusing on delivering value and customer-centric solutions.</p>
                    <div className="row g-0 mb-3">
                        <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Innovative Excellence</h5>
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Expertise That Shines</h5>
                        </div>
                        <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Always-On Assistance</h5>
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Fair Prices</h5>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s">
                        <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: '60px', height: '60px' }}>
                            <i className="fa fa-phone-alt text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="mb-2">Call to ask any question</h5>
                            <h4 className="text-primary mb-0">+91 9371001138</h4>
                        </div>
                    </div>
               
 <NavLink to="/contact">
              <Button className="btn btn-primary py-3 px-5 mt-3 wow zoomIn">Request A Quote</Button>
            </NavLink>

                </div>
                <div className="col-lg-5" style={{ minHeight: '500px' }}>
                    <div className="position-relative h-100">
                        <img  className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="../about/about.jpg" style={{ minHeight: '500px', objectFit: 'cover' }} />
                    </div>
                </div>
            </div>
        </div>

        <div className="container mt-1">
  <div className="row visiso">
    <div className="col-md-6 col-12 text-center ">
      <img src='/images/vision.png' alt="Our Vision" className="p-4 img-fluid" style={{ width: '350px' }}/>
      <h5 className='fw-bold text-primary text-uppercase'>Our Vision</h5>
      <hr />
      <p className="text-justify">
      Our vision at Innovative Solutions IT Services is to establish ourselves as a pioneering entity in the ever-evolving IT industry. We aspire to gain renown for our prowess in delivering transformative, game-changing solutions that catalyze growth and revolutionize businesses. Our aim is to emerge as the preferred partner for organizations in search of innovative concepts and cutting-edge technologies that confer a distinct competitive advantage in their respective markets. With a steadfast commitment to excellence and a relentless pursuit of innovation, we endeavor to shape the future of technology and empower businesses to thrive in an increasingly digital world.      </p>
    </div>
    <div className="col-md-6 col-12 text-center">
      <img  src='/images/mission.png' alt="Our Mission" className="p-4 img-fluid" style={{ width: '360px' }}  />
      <h5 className='fw-bold text-primary text-uppercase'>Our Mission</h5>
      <hr />
      <p className="text-justify" >
      At Innovative Solutions IT Services, our mission is to empower businesses with tailored,
       result-driven IT solutions designed to enhance efficiency, drive productivity, 
       and facilitate sustainable growth. We are dedicated to continually broadening our 
       domain knowledge and technical expertise to adapt to the evolving needs of our clients. 
       By prioritizing customer satisfaction above all else, we strive to cultivate enduring
        partnerships and establish ourselves as trusted advisors in our clients' journeys to
        success. Our unwavering commitment to excellence and innovation fuels our ambition to 
        redefine industry standards and create lasting value for our partners.      
        </p>
    </div>
  
  </div>
</div>

    </div>
    <BackToTopButton></BackToTopButton>
    </>
  );
};

export default About;
