import React, { useState, useEffect } from 'react';

const Interns = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating asynchronous data fetching
    const fetchData = () => {
      setTimeout(() => {
        const fakeData = [
          {
            name: 'Gaurav Lonari',
            profession: 'Fullstack Web Developer',
            comment: 'I have been working as a Full Stack Developer Intern at The Innovative Solutions for almost 3 months now and I wanted to share my positive experience. The onboarding process, led by Mr. Nilesh was smooth and made it easy for me to contribute to the companys objectives. I have been working on a project from scratch and it is almost ready as a prototype. My colleagues, Ms. Gauri, Ms.Sakshi, have been very collaborative and supportive in this process. Mr. Santosh has ensured that I have a comfortable work environment and access to various facilities for a great work-life balance. The team atmosphere is supportive and positive, which has enabled me to give my best effort. I am grateful for the opportunity and look forward to further growth with The Innovative Solutions.',
            image: 'team/gaurav.jpg'
          },
          {
            name: 'Kunjal Pande',
            profession: 'Front Web Developer',
            comment: 'My name is Kunjal and I had the opportunity to go through a three-month Web Development internship at Innovative Solutions. I must say this was an enriching experience for me as an intern.Since I got this opportunity, my goal for this short period was to get acquainted with the culture of the software firm, to learn as much as possible about the web development domain, to expand my knowledge, and to gain hands-on experience in projects. I got the opportunity to work as a team lead of interns. At the time of my internship, I gained precious experience, practical skills, and applied knowledge. I want to note the keen team at Innovative Solutions which openly accepts new people and is ready to help atany moment in time, I wish to say special thanks to Mr. Santosh Sir, Ms. Gauri Mam, and Sakshi Mam for guidance, support, and for their excellent attitude, help, and incredible responsiveness. I will recall these months with the warmest thoughts!',
            image: 'team/Kunjal.jpg'
          },
          
          {
            name: 'Manish Karegaonkar',
            profession: 'Front Web Developer',
            comment: 'I am very glad to tell you about my internship experience at The Innovative Solutions company. It was a great experience of learning and practical implementation of a live project.Currently, I am working as a Full stack web developer intern for the last 3 months got a lot of practical coding experience. I learned many skills like leadership skills, team management, time management, etc while leading my Goda project team. The flounder of our company Mr. Santosh Mundhe sir always guided me to improve my technical as well as soft skills and also directed me to correct track. My colleagues Sakshi and Gauri also helped me a lot in my coding skills development. Both Sakshi and Gauri always helped me when I got any query or error. I think I have learned so many learning as the internship goes on. I will suggest all other students join The Innovative Solution internship as of the experience I learned here from scratch to the final finished product.',
            image: 'team/manish.jpg'
          }
          ,
          {
            name: 'Mansi Mahajan',
            profession: 'Backend Web Developer',
            comment: 'I am very glad to tell you about my internship experience at The Innovative Solutions company. It was a great experience of learning and practical implementation of a live project.Currently, I am working as a Full stack web developer intern for the last 3 months got a lot of practical coding experience. I learned many skills like leadership skills, team management, time management, etc while leading my Goda project team. The flounder of our company Mr. Santosh Mundhe sir always guided me to improve my technical as well as soft skills and also directed me to correct track. My colleagues Sakshi and Gauri also helped me a lot in my coding skills development. Both Sakshi and Gauri always helped me when I got any query or error. I think I have learned so many things during this internship and also I am going to keep learning as the internship goes on. I will suggest all other students join The Innovative Solution internship as of the experience I learned here from scratch to the final finished product.',
            image: 'team/mansi.jpeg'
          },
          ,
          {
            name: 'Nisha Bagade',
            profession: 'Backend Web Developer',
            comment: 'My Name is Nisha Sitaram Bagade I am from sanghavi college of engineering Nashik during the internship in innovative solutions  Nashik is very good The internship was innovative and mind enlarging. Rather than teach us everything, we were made to reason out the why behind things . I enjoyed the internship days with sakshi mam and gauri mam both girls are non IT field still they do thier best that very inspiring to us if they can do it why we not ?and Mr Santosh sir gave us support is incredible He backed it up with practical, real-life experiences.and solve problems on our own.',
            image: 'team/nisha.jpg'
          },
         
      
  
        ];
        setTestimonials(fakeData);
        setLoading(false);
      }, 20); // Simulating 2 seconds delay
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-fluid py-5 wow fadeInUp" >
      <div className="container py-5">
        {/* <div className="section-title text-center position-relative pb-3 mb-4 mx-auto" style={{ maxWidth: '600px' }}>
          <h5 className="fw-bold text-primary text-uppercase">Testimonial</h5>
          <h1 className="mb-0">Intern Testimonials: Insights into Our Digital Solutions</h1>
        </div> */}
        <div className=" row g-5 justify-content-center testimonial-carousel wow fadeInUp">
          {testimonials.map((testimonial, index) => (
            <div key={index} className=" col-lg-6 col-md-2 testimonial-item bg-light my-1">
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img className="img-fluid rounded" src={testimonial.image} style={{ width: '70px', height: '70px' }} alt='img' />
                <div className="ps-4">
                  <h4 className="text-primary mb-1">{testimonial.name}</h4>
                  <small className="text-uppercase">{testimonial.profession} Intern</small>
                </div>
              </div>
              <div className="pt-4 pb-5 px-3 text-justify">
                {testimonial.comment}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Interns;
