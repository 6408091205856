import React from 'react';
import { Route, Routes } from 'react-router-dom'
import Home from '../src/PAGES/Home'
import About from '../src/PAGES/About'
import Career from '../src/PAGES/Career'
import Services from '../src/PAGES/Services'
import Gallery from '../src/PAGES/Gallery'
import Contact from '../src/PAGES/Contact'
import Header from '../src/COMPONENTS/Header'
import Footer from '../src/COMPONENTS/Footer'
import Testimonial from '../src/PAGES/Testimonial'
import Employee from './PAGES/Employee';
import Feedback from './PAGES/Feedback';
import Interns from './PAGES/Interns';
import ScrollToTop from './COMPONENTS/ScrollToTop'
import { FaArrowUp } from "react-icons/fa";

const App = () => {
  return (
    <>
      <a href="/" className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i><FaArrowUp></FaArrowUp></i></a>

      <Header></Header>
      <ScrollToTop></ScrollToTop>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/career' element={<Career />} />
        <Route path='/services' element={<Services />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/contact' element={<Contact />} />

        {/* <Route path="/Feedback" element={<Feedback />} >
          <Route exact path='Testimonial' element={<Testimonial />} />
          <Route exact path='Employee' element={<Employee />} />
          <Route exact path='Interns' element={<Interns />} />

        </Route> */}
      </Routes >
      <Footer></Footer>
    </>
  );
}
export default App;