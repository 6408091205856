import React from "react";
import BackToTopButton from '../COMPONENTS/BackToTopButton'

import { MdBusinessCenter } from "react-icons/md";

const Career = () => {
    return (
        <>
           <div className="container-fluid py-3 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-4">
                <div className="section-title text-center position-relative pb-3 mb-2 mx-auto " style={{maxWidth: '600px'}}>
                    <h5 className="fw-bold text-primary text-uppercase">Career</h5>
                    <h2 className="mb-0">Join Us at The Innovative Solutions - Where Innovation Thrives!</h2>
                </div>
                </div>
                </div>
        <div className="justify-content-center align-ites-center text-center ps-5 pe-5 mx-4 ">
          <p>The Innovative Solutions is a dynamic and rapidly expanding IT services company, committed to pushing the boundaries of innovation. We're constantly seeking exceptional individuals to join our diverse teams across various departments.</p>
          <p>At The Innovative Solutions, you'll experience the vibrancy of a startup coupled with the stability of an established enterprise. Guided by our visionary founders, we're a passionate group of professionals dedicated to fostering creativity and implementing efficient processes that benefit everyone involved – our clients, partners, and employees alike.</p>
        </div>
<br></br>   



<div className="container-fluid py-2 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-4">
                <div className="section-title text-center position-relative pb-3  mx-auto " style={{maxWidth: '600px'}}>
                    <h3 className="mb-0">Exciting Internships Going on!!</h3>
                </div>  </div> </div>
         <div className="container mt-5 ">
                <div className="row bg-light rounded border p-2">
                    <div className="col-md-6 "> 
                        <h4 className="mb-4 ps-2"><i className="py-2 pe-2"><MdBusinessCenter /></i>Full Stack Web Devlopment</h4>
                       <p>
                       <strong>Skills : </strong>HTML,CSS,ReactJs,Javascript,NodeJs,DataBase
                       </p>
                    </div>
                    <div className="col-md-6 mt-5 align-items-center justify-content-center text-center mx-auto">
                        <a href="https://forms.gle/WB82s4fU6WzUqgTn6" target="__blank" className="btn btn-info w-50">Apply Now</a>
                    </div>
                </div>
                <br></br>
                <div className="row bg-light rounded border p-2">
                    <div className="col-md-6 "> 
                        <h4 className="mb-4 ps-2"><i className="pe-2"><MdBusinessCenter /></i>Back end Web Devlopment</h4>
                       <p>
                       <strong>Skills : </strong>Javascript,NodeJs,DataBase
                       </p>
                    </div>
                    <div className="col-md-6 mt-5 align-items-center justify-content-center text-center mx-auto">
                        <a href="https://forms.gle/WB82s4fU6WzUqgTn6" target="__blank" className="btn btn-info w-50">Apply Now</a>
                    </div>
                </div> <br></br>
                <div className="row bg-light rounded border p-2">
                    <div className="col-md-6 "> 
                        <h4 className="mb-4 ps-2"><i className="py-2 pe-2"><MdBusinessCenter /></i>Front End Web Devlopment</h4>
                       <p>
                       <strong>Skills : </strong>HTML,CSS,ReactJs,Javascript
                       </p>
                    </div>
                    <div className="col-md-6 mt-5 align-items-center justify-content-center text-center mx-auto">
                        <a href="https://forms.gle/WB82s4fU6WzUqgTn6" target="__blank" className="btn btn-info w-50">Apply Now</a>
                    </div>
                </div> <br></br>
                <div className="row bg-light rounded border p-2">
                    <div className="col-md-6 "> 
                        <h4 className="mb-4 ps-2"><i className="py-2 pe-2"><MdBusinessCenter /></i>APP Development</h4>
                       <p>
                       <strong>Skills : </strong>HTML,CSS,React Native,Javascript,NodeJs,DataBase
                       </p>
                    </div>
                    <div className="col-md-6 mt-5 align-items-center justify-content-center text-center mx-auto">
                        <a href="https://forms.gle/WB82s4fU6WzUqgTn6" target="__blank" className="btn btn-info w-50">Apply Now</a>
                    </div>
                </div> <br></br>
                <div className="row bg-light rounded border p-2">
                    <div className="col-md-6 "> 
                        <h4 className="mb-4 ps-2"><i className="py-2 pe-2"><MdBusinessCenter /></i>Data Analysis</h4>
                       <p>
                       <strong>Skills : </strong>MySql,Adv.Excel,PowerBi,Tablue,Basics of Python
                       </p>
                    </div>
                    <div className="col-md-6 mt-5 align-items-center justify-content-center text-center mx-auto">
                        <a href="https://forms.gle/WB82s4fU6WzUqgTn6" target="__blank" className="btn btn-info w-50">Apply Now</a>
                    </div>
                </div> <br></br>
                <div className="row bg-light rounded border p-2">
                    <div className="col-md-6 "> 
                        <h4 className="mb-4 ps-2"><i className="py-2 pe-2"><MdBusinessCenter /></i>Digital marketing</h4>
                       <p>
                       <strong>Skills : </strong>Analytics,Content writting,CRM,SEO Management,Video/Grapics creation
                       </p>
                    </div>
                    <div className="col-md-6 mt-5 align-items-center justify-content-center text-center mx-auto">
                        <a href="https://forms.gle/WB82s4fU6WzUqgTn6" target="__blank" className="btn btn-info w-50">Apply Now</a>
                    </div>
                </div> <br></br>
            </div>

            <div className="justify-content-center align-ites-center text-center ps-5 pe-5 mb-2 mx-4 bg-light rounded border py-5 ">
  <div className="fw-bold text-primary text-uppercase mb-2">For Future Job Openings</div>
  Drop us an email with your updated resume at <i className="fw-bold text-primary"> hr@theinnovativesolutions.in</i>
  <br></br>
     We will get back to you as soon as possible.</div>
     <BackToTopButton></BackToTopButton>

        </>
    );
}

export default Career;
