import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Interns from './Interns';
import Testimonial from './Testimonial';
import Employee from './Employee';

const Feedback = () => {
  const [activeTab, setActiveTab] = useState('Testimonial'); // Set default active tab

  // Function to handle tab change
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  // Render the active tab component based on activeTab state
  const renderActiveTab = () => {
    switch (activeTab) {
      case 'Interns':
        return <Interns />;
      case 'Employee':
        return <Employee />;
      case 'Testimonial':
        return <Testimonial />;
      default:
        return null;
    }
  };

  // Function to determine if a button is active
  const isButtonActive = (tabName) => {
    return activeTab === tabName ? 'active' : '';
  };

  return (
    <>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-3">
          <div className="section-title text-center position-relative pb-3 mb-2 mx-auto" style={{ maxWidth: '600px' }}>
            <h5 className="fw-bold text-primary text-uppercase">Testimonial</h5>
            <h1 className="mb-0">What Our People Say About Our Digital Services</h1>
          </div>
        </div>
      </div>
      <div className="text-center">
        <Button className={`btn  py-md-3 px-md-5 me-3 ${isButtonActive('Testimonial')}`} onClick={() => handleTabChange('Testimonial')}>Client</Button>
        <Button className={`btn  py-md-3 px-md-5 me-3 ${isButtonActive('Interns')}`} onClick={() => handleTabChange('Interns')}>Interns</Button>
        <Button className={`btn  py-md-3 px-md-5 me-3 ${isButtonActive('Employee')}`} onClick={() => handleTabChange('Employee')}>Employee</Button>
      </div>
      {renderActiveTab()}
    </>
  );
};

export default Feedback;
