import React from 'react';
import { FaArrowRight  } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { IoMailOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io5";


const Footer = () => {
    return (
        <>
            <div className="container-fluid text-light mt-5 wow fadeInUp footer" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-lg-4 col-md-6 footer-about">
                            <div className="text-justify d-flex flex-column align-items-center justify-content-center text-center h-99 bg-primary p-2">
                                <a href="/home" className="navbar-brand">
                                    <h2 className="m-0 mt-5 text-white">About Comapny</h2>
                                </a>
                                <p className="mt-3 mb-5 text-justify">Innovative Solutions IT Services,
                                 established in 2020, enhances organizations with custom app 
                                 development and strategic solutions. Our ethical approach ensures 
                                 timely deliverables and business growth. With domain expertise, 
                                 we provide budget-friendly, client-centric solutions.</p>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="row gx-5">
                                <div className="col-lg-4 col-md-12 pt-5 mb-5 me-5 ms-2">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Get In Touch</h3>
                                    </div>
                                    <div className="d-flex mb-1">
                                        <i className="bi bi-geo-alt text-primary me-2"><CiLocationOn></CiLocationOn></i>
                                        <p className="mb-0">flat no. 1, Urvashi Apartment, Kusumagraj Smarak Path, Bhavik Nagar, Shreerang Nagar, Nashik, Maharashtra 422005 </p>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-envelope-open text-primary me-2"><IoMailOutline></IoMailOutline></i>
                                        <p className="mb-0">enquiry@innovativesolutions.in</p>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-telephone text-primary me-2"><IoCallOutline></IoCallOutline></i>
                                        <p className="mb-0">+91 9371001138</p>
                                    </div>
                                    <div className="d-flex mt-4">         

                                        <a className="btn btn-primary btn-square me-2" href="https://twitter.com/theinnovatives3?t=ZQHRr29rvQHbPC8qRTnpJg&s=09"><i className="fab fa-twitter fw-normal"></i></a>
                                        <a className="btn btn-primary btn-square me-2" href="https://www.facebook.com/www.theinnovativesolutions.in"><i className="fab fa-facebook-f fw-normal"></i></a>
                                        <a className="btn btn-primary btn-square me-2" href="https://www.linkedin.com/in/the-innovative-solutions-18255222a/"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                        <a className="btn btn-primary btn-square me-2" href="https://www.instagram.com/the.innovative.solutions/"><i className="fab fa-instagram fw-normal"></i></a>
                                        <a className="btn btn-primary btn-square" href="https://wa.me/message/AELL6S2DEZK5G1" target="_blank" rel="noopener noreferrer">                                <i><IoLogoWhatsapp></IoLogoWhatsapp></i>
</a>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5 ms-4">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Quick Links</h3>
                                    </div>
                                    <div className="link-animated d-flex flex-column justify-content-start">
                                        <a className="text-light mb-2 text-decoration-none" href="#"><i className="bi bi-arrow-right text-primary me-2 "> <i><FaArrowRight ></FaArrowRight ></i></i>Home</a>
                                        <a className="text-light mb-2 text-decoration-none" href="/#/about"><i className="bi bi-arrow-right text-primary me-2"> <i><FaArrowRight ></FaArrowRight ></i></i>About Us</a>
                                        <a className="text-light mb-2 text-decoration-none" href="/#/services"><i className="bi bi-arrow-right text-primary me-2"> <i><FaArrowRight ></FaArrowRight ></i></i>Our Services</a>
                                        <a className="text-light mb-2 text-decoration-none" href="/#/gallery"><i className="bi bi-arrow-right text-primary me-2"> <i><FaArrowRight ></FaArrowRight ></i></i>Gallery</a>
                                        <a className="text-light mb-2 text-decoration-none" href="/#/career"><i className="bi bi-arrow-right text-primary me-2"> <i><FaArrowRight ></FaArrowRight ></i></i>Career</a>
                                        <a className="text-light mb-2 text-decoration-none" href="/#/contact"><i className="bi bi-arrow-right text-primary me-2"> <i><FaArrowRight ></FaArrowRight ></i></i>Contact Us</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid text-white" style={{background: '#061429'}}>
                <div className="container text-center">
                    <div className="row justify-content-center ">
                        <div className="col-lg-8 col-md-6">
                            <div className="d-flex align-items-center justify-content-center " style={{height: '75px'}}>
                                <p className="mb-0  ">&copy; <a className="text-white text-decoration-none" href="https://theinnovativesolutions.in/">The Innovative Solutions</a> | All Rights Reserved 
                             <a className="text-white text-decoration-none" href="#"> | Terms & Conditions | Privacy Policy</a></p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
