import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { IoLogoWhatsapp } from "react-icons/io5";


const Header = () => {
    return (
        <>
        {/* Top Navbar */}
            <div className="container-fluid footer px-5 d-none d-lg-block">
                <div className="row gx-0">
                    <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
                        <div className="d-inline-flex align-items-center" style={{ height: '45px' }}>
                            <small className="me-3 text-light">
                                <i className="fa fa-phone-alt me-2"></i>+91 9371001138
                            </small>
                            <small className="text-light">
                                <i className="fa fa-envelope-open me-2"></i>enquiry@innovativesolutions.in
                            </small>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center text-lg-end">
                        <div className="d-inline-flex align-items-center" style={{ height: '45px' }}>
                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="https://twitter.com/theinnovatives3?t=ZQHRr29rvQHbPC8qRTnpJg&s=09" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-twitter fw-normal"></i>
                            </a>
                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="https://www.facebook.com/www.theinnovativesolutions.in" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-facebook-f fw-normal"></i>
                            </a>
                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="https://www.instagram.com/the.innovative.solutions/" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-instagram fw-normal"></i>
                            </a>
                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="https://www.linkedin.com/in/the-innovative-solutions-18255222a/" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-linkedin-in fw-normal"></i>
                            </a>

                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href="https://wa.me/message/AELL6S2DEZK5G1" target="_blank" rel="noopener noreferrer">
                                <i><IoLogoWhatsapp></IoLogoWhatsapp></i>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        {/* Top Navbar */}

      <Navbar expand="lg" className="bg-body-tertiary ">
      <Container>
        <Navbar.Brand href="#home" className='align-items-start'>
          <img className="navbar-logo" src="../img/innovativelogo-removebg-preview.png" alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav " >
          <Nav className="ms-auto navbar-dark navbar-nav ">
            <NavLink className="nav-link" to="/">Home</NavLink>
            <NavLink className="nav-link" to="/about">About</NavLink>
            <NavLink className="nav-link" to="/services">Services</NavLink>
            <NavLink className="nav-link" to="/career">Career</NavLink>
            <NavLink className="nav-link" to="/gallery">Gallery</NavLink>
            <NavLink className="nav-link me-2" to="/contact">Contact</NavLink>
            <NavLink to="/contact">
              <Button className='btn btn-primary' variant="primary">Get Quote</Button>
            </NavLink>
           
          </Nav>
          
        </Navbar.Collapse>
       
      </Container>
     
    </Navbar>  
     {/* <hr className='hr'></hr> */}

      
     <div className="overlay-content ">
        <br></br>
        <div className="p-1 content" style={{ maxWidth: '1000px', margin: 'auto' }}>
        <h2 class=" mb-md-6 text-uppercase">Where Innovation Meets Results, Every Solution Tailored for Success!!!</h2>
        {/* <h5 class="text-dark text-uppercase mb-3  slideInDown">Creative & Innovative Digital Solution</h5> */}
                
      <NavLink to="/contact">
      <Button className="btn btn-primary py-md-3 px-md-5 me-3 mt-2 " >Free Quote</Button>
     </NavLink>
            <NavLink to="/contact">
              <Button className="btn btn-secondary py-md-3 px-md-5 mt-2 ">Contact Us</Button>
            </NavLink>
                   
                </div>
      
                </div>       
        </>
    );
}

export default Header;
